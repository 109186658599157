<template>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.889 4.889L19.11 19.11M19.111 4.889L4.89 19.11"
      stroke="#797088"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>

<script>
export default {}
</script>
