<template>
  <svg
    width="30"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#fff"
      d="M9 25H4V10h5v15zM6.501 8a2.5 2.5 0 1 1 0-5.001A2.5 2.5 0 0 1 6.5 8zM27 25h-4.807v-7.3c0-1.741-.033-3.98-2.499-3.98-2.503 0-2.888 1.896-2.888 3.854V25H12V9.989h4.614v2.051h.065c.642-1.18 2.211-2.424 4.551-2.424 4.87 0 5.77 3.109 5.77 7.151V25z"
    />
  </svg>
</template>

<script>
export default {}
</script>
