<template>
  <div>
    <client-only>
      <iframe :src="src" :title="title" width="100%" :height="height">
        <div v-html="link"></div>
      </iframe>
    </client-only>
  </div>
</template>

<script>
import { config } from '@/config'

export default {
  name: 'EmbeddedPlayerIFrame',

  props: {
    title: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      iframeMaxHeight: 320,
      height: 150
    }
  },

  mounted () {
    window.addEventListener('message', this.receiveIFrameMessage)
  },

  beforeDestroy () {
    window.removeEventListener('message', this.receiveIFrameMessage)
  },

  methods: {
    receiveIFrameMessage (event) {
      if (event.origin !== config.embeddedPlayerUrl) {
        return
      }
      const height = event.data.height
      if (height) {
        this.height = Math.min(this.iframeMaxHeight, height)
      }
    }
  }
}
</script>
