<template lang="pug">
.embedded-player-modal
  BModal.embedded-player-modal__buefy(
    :width="660"
    :active="embeddedPlayerModalIsActive"
    :can-cancel="['escape', 'outside']"
    scroll="keep"
    @close="closeEmbeddedPlayerModal"
  )
    .embedded-player-modal__content
      .embedded-player-modal__head
        span.embedded-player-modal__head-title {{ $t('modules.embedded_player.title') }}
        IconClose.embedded-player-modal__head-close(
          @click.native.prevent="closeEmbeddedPlayerModal"
        )
      .embedded-player-modal__body
        EmbeddedPlayerIFrame.embedded-player-modal__body-iframe(
          :title="getIFrameTitle()"
          :src="iframeSrc"
          :episodeId="episodeId || 'latest'"
          :link="getIFrameLink()"
        )
        fieldset.embedded-player-modal__body-mode
          template(v-for="option in modes")
              input.embedded-player-modal__body-mode-input(type="radio" v-model="activeMode" :id="option.id" name="mode" :value="option.id")
              label.embedded-player-modal__body-mode-label(:for="option.id")
                .embedded-player-modal__body-mode-label-head
                  BRadio(
                    v-model="activeMode !== option.id"
                    @click.native.prevent="activeMode = option.id"
                  )
                  h5.embedded-player-modal__body-mode-label-head-title {{ option.title }}
                p.embedded-player-modal__body-mode-label-body {{ option.description }}
        .embedded-player-modal__body-theme
          h5.embedded-player-modal__body-theme-title {{ $t('modules.embedded_player.theme_title') }}
          fieldset.embedded-player-modal__body-theme-field
            template(v-for="option in themes")
              input.embedded-player-modal__body-theme-field-input(type="radio" v-model="activeTheme" :id="option.id" name="theme" :value="option.id")
              label.embedded-player-modal__body-theme-field-label(:for="option.id") {{ option.text }}

        BInput.embedded-player-modal__body-code(
          type="textarea"
          maxlength="300"
          disabled="true"
          :value="getCode()"
        )
      .embedded-player-modal__footer
        button.embedded-player-modal__footer-btn(
          @click="copyToClipboard"
        )
          CopyIcon.embedded-player-modal__footer-btn-icon
          template.embedded-player-modal__footer-btn-text {{ $t('modules.embedded_player.copy_button') }}
</template>

<script>
import { mapActions } from 'vuex'
import { config } from '@/config'
import toast from '@/lib/toast'
import { copyToClipboard } from '@/lib/utils'

import EmbeddedPlayerIFrame from '@/components/modals/embedded-player/EmbeddedPlayerIFrame.vue'
import IconClose from '@/components/icons/dashboard/Close.vue'
import CopyIcon from '@/components/icons/dashboard/Copy.vue'

export default {
  components: {
    IconClose,
    CopyIcon,
    EmbeddedPlayerIFrame
  },

  props: {
    podcast: {
      type: Object,
      default: null
    },
    episode: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      activeTheme: 'default-theme',
      activeMode: this.episode ? 'single-mode' : 'latest-mode'
    }
  },

  computed: {
    themes () {
      return [{
        id: 'default-theme',
        value: 'default',
        text: this.$t('single_words.default')
      }, {
        id: 'light-theme',
        value: 'light',
        text: this.$t('single_words.light')
      }, {
        id: 'dark-theme',
        value: 'dark',
        text: this.$t('single_words.dark')
      }]
    },

    modes () {
      const singleMode = {
        id: 'single-mode',
        title: this.$t('modules.embedded_player.modes.single_mode.title'),
        description: this.$t('modules.embedded_player.modes.single_mode.description') + this.episodeTitle
      }
      const latestMode = {
        id: 'latest-mode',
        title: this.$t('modules.embedded_player.modes.latest_mode.title'),
        description: this.$t('modules.embedded_player.modes.latest_mode.description')
      }
      const showMode = {
        id: 'show-mode',
        title: this.$t('modules.embedded_player.modes.show_mode.title'),
        description: this.$t('modules.embedded_player.modes.show_mode.description')
      }

      if (this.episodeId) {
        return [singleMode, showMode]
      } else {
        return [latestMode, showMode]
      }
    },

    embeddedPlayerModalIsActive () {
      return Boolean(this.$route.query.embedded)
    },

    activeThemeName () {
      const theme = this.themes.find((t) => {
        return t.id === this.activeTheme
      })

      if (!theme || theme.id === 'default-theme') {
        return ''
      }

      return theme.value
    },

    iframeSrc () {
      let src = `${config.embeddedPlayerUrl}/${this.$route.params.slug}/`

      const modeParams = this.getModeParams()
      src = src + modeParams

      if (this.activeThemeName) {
        src = src + `?theme=${this.activeThemeName}`
      }

      if (this.$route.query.time) {
        src = src + `?time=${this.$route.query.time}`
      }
      return src
    },

    episodeId () {
      if (isNaN(this.$route.query.episode)) {
        return null
      }

      return parseInt(this.$route.query.episode)
    },

    episodeTitle () {
      return this.episode?.title.replace(/"([^"]*)"/g, '«$1»')
    }
  },

  async mounted () {
    const podcastSlug = this.$route.params.slug
    if (podcastSlug) {
      const episodes = await this.getEpisodes({ slug: this.$route.params.slug, limit: 6 })
      this.episodesLength = episodes.length
    }
  },

  methods: {
    ...mapActions('podcasts', ['getEpisodes']),

    closeEmbeddedPlayerModal () {
      this.$router.push({
        query: {
          embedded: undefined
        }
      })
    },

    copyToClipboard () {
      copyToClipboard(this.getCode())
      toast(this.$t('sentences.copied_to_clipboard'), 'info')
    },

    getModeParams () {
      let modeParams = ''

      switch (this.activeMode) {
        case 'single-mode':
          modeParams = this.episodeId
          break
        case 'latest-mode':
          modeParams = 'latest'
          break
        case 'show-mode':
          modeParams = ''
          break
        default:
          modeParams = ''
          break
      }

      return modeParams
    },

    getIFrameTitle () {
      const episodeTitle = this.episodeTitle
      const podcastTitle = this.podcast.title

      return episodeTitle && this.activeMode === 'single-mode' ? episodeTitle : podcastTitle
    },

    getIFrameLink () {
      const podcastSlug = this.$route.params.slug
      const episodeId = this.episodeId
      let url = `${config.baseUrl}/podcasts/${podcastSlug}/`
      url = episodeId && this.activeMode === 'single-mode' ? url + episodeId : url
      return `<a href="${url}">${this.getIFrameTitle()}</a>`
    },

    getStaticHeight () {
      return this.activeMode === 'single-mode' || this.activeMode === 'latest-mode' ? 154 : 345
    },

    getCode () {
      const title = this.getIFrameTitle()
      const height = this.getStaticHeight()
      const link = this.getIFrameLink()
      return `<iframe src="${this.iframeSrc}" title="${title}" width="100%" height="${height}px" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen scrolling="no">${link}</iframe>`
    }
  }
}
</script>

<style lang="scss" scoped>
.embedded-player-modal {
  &__content {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 0px 1px rgba(14, 8, 60, 0.08), 0px 4px 13px rgba(14, 8, 60, 0.12);
    max-height: 100%;

    @include display-less(phablet) {
      width: 100%;
      max-height: 100%;
      border: 0;
      border-radius: 0;
      position: fixed;
      bottom: 0;
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7;
    min-height: 56px;
    align-items: center;
    border-radius: 8px 8px 0 0;

    @include display-less(phablet) {
      border-radius: 0;
    }

    &-close {
      cursor: pointer;
      margin-left: auto;
      margin-right: $gap * 1.5;
    }
    &-title {
      color: #2d2d2d;
      font-size: 16px;
      font-weight: 700;
      margin-left: $gap * 1.5;
    }
  }

  &__body {
    margin-top: $gap;
    display: flex;
    flex-direction: column;
    gap: $gap * 1.5;
    overflow-y: auto;

    &-iframe {
      margin: 0 $gap;
    }

    &-mode {
      display: flex;
      flex-direction: row;
      gap: $gap;
      margin: 0 $gap;

      &-input {
        display: none;
      }

      &-label {
        cursor: pointer;
        width: 100%;
        border: 1px solid #DFDFDF;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        padding: $gap;
        background-color: #FFFFFF;
        gap: $gap * 0.75;

        &-head {
          display: flex;

          &-title {
            font-size: 14px;
            font-weight: 700;
            margin-left: $gap * -0.75;
          }
        }

        &-body {
          font-size: 12px;
          margin: 0;
        }
      }

      &-input:checked + &-label{
        background-color: #F1EFFF;
        border: 1px solid #6C26D6;
      }
    }

    &-theme {
      margin: 0 $gap;

      &-title {
        color: #2D2D2D;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: $gap * 0.5;
      }

      &-field {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #DFDFDF;

        &-input {
          display: none;
        }

        &-label {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          font-weight: 700;
          color: #797088;
          height: 37px;
          cursor: pointer;

          &:first-of-type {
            border-radius: 8px 0 0 8px;
          }

          &:last-of-type {
            border-radius: 0 7px 7px 0;
          }
        }

        &-input:checked + &-label{
          color: #FFFFFF;
          background-color: #6C26D6;
        }
      }
    }

    &-code {
      margin: 0 $gap;

      /deep/ textarea {
        opacity: 1;
        color: #777;
        resize: none;
        height: 130px;
        min-height: 130px;
        -webkit-text-fill-color: #777;
      }
    }
  }

  &__footer {
    display: flex;
    background-color: #f7f7f7;
    min-height: 72px;
    align-items: center;
    border-radius: 0 0 8px 8px;
    display: flex;
    justify-content: flex-end;

    &-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: $gap * 0.5;
      border-radius: 4px;
      background-color: #FFFFFF;
      border: 1px solid #DFDFDF;
      padding: $gap * 0.5 $gap * 1.5;
      cursor: pointer;
      margin: 0 $gap;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #2D2D2D;

      @include display-less(phablet) {
        width: 100%;
      }

      &-icon {
        fill: #2D2D2D;
      }
    }
  }
}
</style>
