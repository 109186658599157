<template lang="pug">
  .distribution(
    v-if="countOfActiveDirectories > 0"
  )
    List(
      v-if="countOfActiveDirectories === 1"
      :podcast="podcastData"
    )

    a.distribution__button(
      v-if="countOfActiveDirectories > 1"
      @click.prevent="openDistributionModal"
    )
      IconSpeaker.distribution__button-icon
      span.distribution__button-text {{ $t('pages.distribution.listen_on') }} ...
</template>

<script>
import List from './list'
import IconSpeaker from '@/components/icons/dashboard/Speaker'
import ArrowRightIcon from '@/components/icons/dashboard/EpisodeItemArrow'

export default {
  components: {
    List,
    IconSpeaker,
    ArrowRightIcon
  },

  props: {
    podcastData: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    countOfActiveDirectories () {
      if (!this.podcastData) {
        return 0
      }

      const countOfActiveDirectories = this.podcastData.directories.filter((el) => {
        return (Boolean(el.link_url) === true && el.directory_name !== 'ListenNotes')
      }).length

      return countOfActiveDirectories
    }
  },

  methods: {
    openDistributionModal () {
      this.$router.push({
        query: {
          'listen-on': true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  display: flex;
  grid-gap: $gap * 1.5;

  .share-btn {
    margin-left: $gap;
  }

  &__button {
    color: #fff;
    display: flex;
    font-weight: 600;
    padding: 7px 8px;
    grid-gap: $gap / 2;
    border-radius: 8px;
    padding-right: $gap;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    transition: all .2s ease;
    border: 1px solid #C2BEC8;
    width: 100%;

    &:not(.share-btn_opened) {
      &:hover {
        border: 1px solid #fff;
      }
    }

    &_opened {
      border-color: transparent;
    }

    &-arrow {
      margin-left: 5px;
      transform: rotate(180deg);

      /deep/ path {
        stroke: #fff;
      }
    }

    &-icon {
      width: 20px;
      height: 20px;

      /deep/ path {
        fill: #fff;
      }
    }

    &-text {
      color: #fff;
      font-weight: 600;
    }
  }
}
</style>
