<template lang="pug">
a.link(
  :href="link"
  rel="noindex"
  target="blank"
)
  .link__icon
    slot
  .link__content
    span {{ $t('pages.distribution.listen_on') }}
    strong {{ name }}
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  height: 43px;
  outline: none;
  display: flex;
  justify-content: center;
  padding: 7px 7px;
  padding-left: 8px;
  border-radius: 8px;
  text-decoration: none;
  background-color: #000;
  border: 1px solid #A6A6A6;

  &__icon {
    width: 26px;
    height: 26px;
    margin-right: 10px;

    /deep/ svg {
      width: auto;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    > span {
      color: #fff;
      line-height: 1;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 1px;
    }

    > strong {
      color: #fff;
      line-height: 1;
      font-size: 14px;
    }
  }
}
</style>
