<template lang="pug">
ul.distribution__links(
  :class="{'distribution__links_grid': grid}"
)
  template(
    v-for="directory of activeDirectories"
  )
    li.distribution__links-el(
      v-if="directoriesData[directory.directory_name]"
      :key="directory.directory_id"
    )
      DistributionLink.distribution__link(
        :link="directory.link_url"
        :name="directoriesData[directory.directory_name].name"
      )
        component(
          :is="directoriesData[directory.directory_name].icon"
        )
</template>

<script>
import DistributionLink from './link'

import TuneInIcon from '@/components/icons/dashboard/platforms/TuneIn.vue'
import DeezerIcon from '@/components/icons/dashboard/platforms/Deezer.vue'
import PandoraIcon from '@/components/icons/dashboard/platforms/Pandora.vue'
import SamsungIcon from '@/components/icons/dashboard/platforms/Samsung.vue'
import PodcastIndexIcon from '@/components/icons/dashboard/platforms/PodcastIndexIcon.vue'
import SpotifyIcon from '@/components/icons/dashboard/platforms/Spotify.vue'
import AmazonMusicIcon from '@/components/icons/dashboard/platforms/AmazonMusic.vue'
import IHeartRadioIcon from '@/components/icons/dashboard/platforms/IHeartRadio.vue'
import ApplePodcastsIcon from '@/components/icons/dashboard/platforms/ApplePodcasts.vue'
import GooglePodcastsIcon from '@/components/icons/dashboard/platforms/GooglePodcasts.vue'

export default {
  components: {
    TuneInIcon,
    DeezerIcon,
    PandoraIcon,
    PodcastIndexIcon,
    SamsungIcon,
    SpotifyIcon,
    AmazonMusicIcon,
    IHeartRadioIcon,
    ApplePodcastsIcon,
    GooglePodcastsIcon,

    DistributionLink
  },

  props: {
    grid: {
      type: Boolean,
      default: false
    },
    podcast: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      directoriesData: {
        'Tune In': {
          icon: 'TuneInIcon',
          name: 'Tune In'
        },
        Deezer: {
          icon: 'DeezerIcon',
          name: 'Deezer'
        },
        Pandora: {
          icon: 'PandoraIcon',
          name: 'Pandora'
        },
        PodcastIndex: {
          icon: 'PodcastIndexIcon',
          name: 'Podcast Index'
        },
        Spotify: {
          icon: 'SpotifyIcon',
          name: 'Spotify'
        },
        Samsung: {
          icon: 'SamsungIcon',
          name: 'Samsung'
        },
        iTunes: {
          icon: 'ApplePodcastsIcon',
          name: 'Apple Podcasts'
        },
        iHearthRadio: {
          icon: 'IHeartRadioIcon',
          name: 'iHeartRadio'
        },
        'Google Podcast': {
          icon: 'GooglePodcastsIcon',
          name: 'Google Podcasts'
        },
        'Amazon Music': {
          icon: 'AmazonMusicIcon',
          name: 'Amazon Music'
        }
      }
    }
  },

  computed: {
    activeDirectories () {
      if (!this.podcast || !this.podcast.directories) {
        return []
      }

      const directories = this.podcast.directories.filter((el) => {
        return el.link_url && el.directory_name !== 'ListenNotes'
      })

      return directories
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  &__links {
    width: 100%;
    display: flex;
    list-style: none;
    &_grid {
      width: 100%;
      display: grid;
      grid-gap: $gap * 1;
      grid-template-columns: 1fr 1fr 1fr;

      @include display-less(phablet) {
        grid-template-columns: 1fr 1fr;
      }

      @include display-less(smart) {
        grid-template-columns: 1fr;
      }
    }

    &-el {
      width: 100%;
      &:first-child {
        display: block;
      }

      @include display-less(desktop) {
        display: block;
      }
    }

    &_open {
      .distribution__links-el {
        display: block;
      }
    }
  }
}
</style>
