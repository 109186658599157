<template lang="pug">
.share-modal
  BModal.share-modal__buefy(
    :width="640"
    :active="isActive"
    :can-cancel="['escape', 'outside']"
    @close="close"
  )
    .share-modal__content
      IconClose.share-modal__close(
        @click.native.prevent="close"
      )
      span.share-modal__title {{ $t('pages.distribution.listen_on') }}
      span.share-modal__text {{ $t('pages.distribution.listen_on_text') }}
      ListenOnList(
        grid
        :podcast="podcast"
      )
</template>

<script>
import IconClose from '@/components/icons/dashboard/Close'

import ListenOnList from '@/components/shared/elements/distribution/list.vue'

export default {
  components: {
    IconClose,
    ListenOnList
  },

  props: {
    podcast: {
      type: Object,
      default: null
    }
  },

  computed: {
    isActive () {
      return this.$route.query['listen-on']
    }
  },

  methods: {
    close () {
      this.$router.push({
        query: {
          'listen-on': undefined
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.share-modal {
  &__content {
    display: flex;
    position: relative;
    border-radius: 4px;
    flex-direction: column;
    background-color: #fff;
    padding: $gap * 1.5 $gap * 2;
    box-shadow: 0px 0px 1px rgba(14, 8, 60, 0.08), 0px 4px 13px rgba(14, 8, 60, 0.12);
  }

  &__close {
    cursor: pointer;
    top: $gap * 1.5;
    right: $gap * 1.5;
    position: absolute;
  }

  &__title {
    color: #2D2D2D;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: $gap * 0.5;
  }

  &__text {
    color: #2D2D2D;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    margin-bottom: 1.5 * $gap;
  }
}
</style>
